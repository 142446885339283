import typeOf from 'just-typeof'
import { getConstraints } from '@/utils/constraints'

export const extendedConstraints = (constraints) => ({
  taxes: null,
  product_groups: null,
  products: null,
  ...getConstraints(constraints)
})

export function genInitialData({ currency = 'EUR' } = {}) {
  return {
    name: null,
    rate: null,
    value: null,
    type: 'value',
    group: 'cart',
    behaviors: {
      cart: 'per_item',
      automatic: false
    },
    constraints: extendedConstraints(),
    currency,
    locations: null,
    branch_groups: null,
    group_on_receipt: false
  }
}

export function cleanProductsConstraints(arr) {
  if (!Array.isArray(arr)) return null
  return typeOf(arr[0]) === 'object' ? arr.map((p) => p.id) : arr
}
