<template>
  <th-wrapper
    :title="$t('pages.discounts.edit.sections.constraints.title')"
    :subtitle="$t('pages.discounts.edit.sections.constraints.subtitle')"
    body-class="p-8 pb-3"
    collapsable
    collapsed-by-default
  >
    <el-form ref="form" :model="form">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item
            :label="$t('common.discounts.constraints.taxes')"
            prop="constraints.taxes"
          >
            <th-resource-select
              id="constraints.taxes"
              v-model="taxesConstraints"
              data-testid="tax-constraint"
              class="w-full"
              resource="taxes"
              :computed-fields="['name', 'fa_account_number']"
              :resource-query="{ query: { deleted: false } }"
              multiple
            />
          </el-form-item>

          <el-form-item
            :label="$t('common.discounts.constraints.product_groups')"
            prop="constraints.product_groups"
          >
            <th-resource-select
              id="constraints.product_groups"
              v-model="productGroupsConstraints"
              data-testid="product-group-constraint"
              class="w-full"
              resource="productGroups"
              :computed-fields="['product_group_id', 'name']"
              :resource-query="{ query: { deleted: false } }"
              multiple
            />
          </el-form-item>

          <el-form-item
            :label="$t('common.discounts.constraints.products')"
            prop="constraints.products"
          >
            <products-select
              id="constraints.products"
              v-model="form.constraints.products"
              class="w-full"
              :resources="resources"
              :extend-query="{ sellable: true }"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import ProductsSelect from '@/containers/multi-items-select/products'

export default {
  components: { ProductsSelect },

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    resources: {
      type: Object,
      required: true
    }
  },

  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    },
    taxesConstraints: {
      get() {
        return this.form.constraints.taxes || null
      },
      set(value) {
        const _value = Array.isArray(value) && value.length ? value : null
        this.form.constraints.taxes = _value
      }
    },
    productGroupsConstraints: {
      get() {
        return this.form.constraints.product_groups || null
      },
      set(value) {
        const _value = Array.isArray(value) && value.length ? value : null
        this.form.constraints.product_groups = _value
      }
    }
  },

  methods: {
    resetFields() {
      this.$refs.form.resetFields()
    },

    validate() {
      return new Promise((resolve, _) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
