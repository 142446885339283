import pick from 'just-pick'
import get from 'just-safe-get'
import set from 'just-safe-set'
import baseModel from '@/models/base-model-new'
import { isEmptyObject, isEmptyArray } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import { extendedConstraints, cleanProductsConstraints } from '../helpers'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  setup(model) {
    const store = useStore()
    const defaultCurrency = computed(
      () => store.getters['Config/getCurrentDefaultCurrency']
    )
    const methods = {
      parseResponse(response) {
        const data = response?.data || {}
        const cleanedPayload = pick(data, Object.keys(model))

        return {
          id: data.id,
          ...model,
          ...cleanedPayload,
          behaviors: { ...cleanedPayload.behaviors },
          constraints: extendedConstraints(cleanedPayload.constraints),
          currency: cleanedPayload.currency || defaultCurrency.value || 'EUR'
        }
      },
      parseDataBeforeSave(form) {
        const result = {}

        // replace empty objects, emtpy arrays and empty strings with null
        Object.keys(form).forEach((key) => {
          const isEmpty =
            isEmptyObject(form[key]) ||
            isEmptyArray(form[key]) ||
            isEmptyString(form[key])
          result[key] =
            isEmpty && !['branch_groups', 'locations'].includes(key)
              ? null
              : form[key]
        })

        const products = get(result, 'constraints.products')
        set(result, 'constraints.products', cleanProductsConstraints(products))

        return result
      }
    }
    return baseModel.setup(model, 'discounts', methods)
  }
}
