<template>
  <th-page-wrapper
    color="var(--secondary-color)"
    align-title="center"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
    @close-requested="$router.push({ name: 'discounts-list' })"
  >
    <discount-form ref="form" />
  </th-page-wrapper>
</template>

<script>
import DiscountForm from './components/form.vue'

export default {
  components: {
    DiscountForm
  },
  computed: {
    isNew() {
      const id = this.$route.params.id
      return !id || id === 'new'
    },
    actions() {
      return {
        permissionPrefix: 'products:discounts',
        returnedPath: { name: 'discounts-list' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>
