<template>
  <section class="p-8">
    <!-- General -->
    <discounts-general ref="general" v-model="current" :resources="resources" />

    <!-- Validity -->
    <discounts-validity ref="validity" v-model="current.constraints" />

    <!-- Constraints -->
    <discounts-constraints
      v-if="current"
      ref="constraints"
      v-model="current"
      :resources="resources"
    />

    <!-- Got to old version -->
    <div class="text-right -mt-2 mb-4">
      <router-link :to="oldVersionLink" class="inline-block">
        <el-button plain>
          {{ $t('common.forms.labels.old_flow') }}
        </el-button>
      </router-link>
    </div>
  </section>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import get from 'just-safe-get'
import flush from 'just-flush'
import pAll from 'p-all'
import { mapGetters } from 'vuex'
import DiscountsValidity from '@/containers/validity-form-section.vue'
import DiscountsGeneral from './discounts-general'
import DiscountsConstraints from './discounts-constraints.vue'
import discountModel from '../model/discounts-model'
import { genInitialData } from '../helpers'

export default {
  components: {
    DiscountsGeneral,
    DiscountsValidity,
    DiscountsConstraints
  },
  setup() {
    return discountModel.setup(genInitialData())
  },
  data() {
    return {
      resources: {}
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation',
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      currencies: 'Config/getAvailableCurrencies'
    }),
    isPercentage() {
      return this.current && this.current.type === 'percentage'
    },
    taxesConstraints: {
      get() {
        return this.current.constraints.taxes || null
      },
      set(value) {
        const _value = Array.isArray(value) && value.length ? value : null
        this.current.constraints.taxes = _value
      }
    },
    productGroupsConstraints: {
      get() {
        return this.current.constraints.product_groups || null
      },
      set(value) {
        const _value = Array.isArray(value) && value.length ? value : null
        this.current.constraints.product_groups = _value
      }
    },
    rules() {
      return {
        name: [
          {
            required: true,
            message: this.$t('pages.discounts.edit.form.rules.name.required'),
            trigger: 'blur'
          }
        ],
        rate: [
          {
            required: this.current.type === 'percentage',
            message: this.$t('pages.discounts.edit.form.rules.rate.required'),
            trigger: 'blur'
          }
        ],
        value: [
          {
            required: this.current.type === 'value',
            message: this.$t('pages.discounts.edit.form.rules.value.required'),
            trigger: 'blur'
          }
        ],
        'behaviors.cart': [
          {
            required: true,
            message: this.$t(
              'pages.discounts.edit.form.properties.behaviors.cart.required'
            ),
            trigger: 'blur'
          }
        ]
      }
    },
    shouldDisplayAutomaticToggle() {
      return this.current.type !== 'value'
    },
    showCurrencySelector() {
      return this.current.type === 'value' && this.currencies.length > 1
    },
    oldVersionLink() {
      return this.isNew
        ? { name: 'discounts-new-v1' }
        : { name: 'discount-edit-v1', params: { id: this.id } }
    }
  },

  mounted() {
    this.fetchResources()
    this.id = this.$route.params.id
    this.fetch()
    if (this.isNew && this.defaultCurrency) {
      this.current.currency = this.defaultCurrency
    }
  },

  methods: {
    async submitForm() {
      const formIsValid =
        (await this.$refs.general.validate()) &&
        (await this.$refs.validity.validate()) &&
        (await this.$refs.constraints.validate())

      if (!formIsValid) {
        return this.$message({
          type: 'warning',
          title: this.$t('common.forms.warning.invalid_input.title'),
          message: this.$t('common.forms.warning.invalid_input.message')
        })
      }

      const isAmountValid = await this.validateAmount()
      if (!isAmountValid) return

      const shouldWarnEmptyConstraints =
        get(this.current, 'behaviors.automatic') &&
        !flush([
          get(this.current, 'constraints.product_groups'),
          get(this.current, 'constraints.taxes'),
          get(this.current, 'constraints.products')
        ]).length

      if (shouldWarnEmptyConstraints) {
        try {
          await this.$confirm(
            this.$t(
              'pages.discounts.edit.form.warnings.automatic_apply.empty_constraints'
            ),
            this.$t('common.titles.warning'),
            {
              confirmButtonText: this.$t('common.interactions.buttons.ok'),
              cancelButtonText: this.$t('common.interactions.buttons.cancel'),
              type: 'warning'
            }
          )
        } catch {
          return
        }
      }

      const isNew = this.isNew
      if (isNew) {
        await this.trackDiscountCreated()
      }

      const resource = this.$t('common.resource.discount.singular')
      const { error } = await this.save()
      if (error) {
        //save fail
        const errorMessage = isNew
          ? this.$t('common.error.action.create.single', { resource })
          : this.$t('common.error.action.update.single', { resource })
        this.$logException(error, { message: errorMessage })
        return
      }

      //save success
      const successMessage = isNew
        ? this.$t('common.success.action.create.single', { resource })
        : this.$t('common.success.action.update.single', { resource })
      this.$message({
        type: 'success',
        message: successMessage
      })

      if (isNew) {
        this.routeTo(this.id)
      }
    },

    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },

    async handleDelete() {
      const confirm = await this.$askToDelete(this.current.name || this.id)
      if (confirm) this.deleteDiscount()
    },

    async deleteDiscount() {
      const { error } = await this.deleteModel()
      if (error) {
        const errorMessage = this.$t('common.error.action.delete.single', {
          resource: this.$t('common.resource.discount.singular')
        })
        this.$logException(error, { message: errorMessage })
        return
      }
      this.$router.push({ name: 'discounts-list' })
    },

    async validateAmount() {
      if (this.current.type === 'percentage' && this.current.rate === 1) {
        try {
          await this.$confirm(
            this.$t('pages.discounts.edit.messages.percentage.confirmation'),
            this.$t('pages.discounts.edit.messages.percentage.attention'),
            {
              confirmButtonText: this.$t('common.interactions.buttons.yes'),
              cancelButtonText: this.$t('common.interactions.buttons.no')
            }
          )
          return true
        } catch {
          return false
        }
      } else if (this.current.type === 'percentage' && this.current.rate > 1) {
        const errorMessage = this.$t(
          'pages.discounts.edit.form.errors.percentage.exceeds'
        )
        this.$logException(new Error(errorMessage), { message: errorMessage })
        return false
      }
      return true
    },

    async fetchResources() {
      try {
        const {
          branchesV1 = [],
          branchGroups = [],
          productGroups = [],
          taxes = [],
          accounts = []
        } = await this.$resourceFetch(
          'branchesV1',
          'branchGroups',
          'productGroups',
          'taxes',
          {
            resource: 'accounts',
            handler: () =>
              th.accounts().getAll({
                query: { type: 'revenue', deleted: false, active: true }
              })
          }
        )
        this.resources = {
          branches: branchesV1,
          branchGroups,
          productGroups,
          taxes,
          accounts
        }
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },

    async trackDiscountCreated() {
      const typeMap = {
        percentage: this.$t('pages.discounts.edit.form.properties.rate.label'),
        value: this.$t('pages.discounts.edit.form.properties.value.label')
      }

      const inst = th.products()
      const actions = (
        this.current.constraints.products || []
      ).map((item) => () => inst.get(item.id))
      const res = await pAll(actions)
      const products = res.map((item) => item.data)

      const taxesIds = this.current.constraints.taxes || []
      const productGroupsIds = this.current.constraints.product_groups || []

      const taxes = (this.resources.taxes || []).filter((item) =>
        taxesIds.includes(item.id)
      )
      const productGroups = (
        this.resources.productGroups || []
      ).filter((item) => productGroupsIds.includes(item.id))

      const props = {
        discount_type: typeMap[this.current.type],
        discount_taxes: taxes.map((item) => item.name),
        discount_product_groups: productGroups.map(
          (item) => `${item.product_group_id} - ${item.name}`
        ),
        discount_products: products.map(
          (item) => `${item.custom_id} - ${item.name}`
        )
      }

      this.$ampli.eventWithBaseProps('discountCreated', props)
    },

    routeTo(id) {
      if (this.navigationAfterCreation === 'edit') {
        this.$router.push({ name: 'discount-edit', params: { id } })
      } else {
        this.$router.push({ name: 'discounts-list' })
      }
    }
  }
}
</script>
