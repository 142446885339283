<template>
  <th-wrapper
    :title="$t('common.titles.general_info.title')"
    body-class="p-8 pb-3"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="20">
        <el-col :sm="12" :md="12" :lg="7">
          <!-- Discount Name -->
          <el-form-item prop="name" :label="$t('common.forms.labels.name')">
            <el-input
              id="name"
              v-model="form.name"
              :placeholder="$t('common.forms.labels.name')"
            />
          </el-form-item>
        </el-col>

        <el-col :sm="12" :md="12" :lg="4">
          <!-- Discount Type -->
          <el-form-item prop="type" :label="$t('common.forms.labels.type')">
            <el-radio-group
              id="type"
              v-model="form.type"
              aria-labelledby="type"
              size="large"
            >
              <el-radio-button label="percentage">
                {{
                  $t('pages.discounts.edit.form.properties.type.values.percent')
                }}
              </el-radio-button>
              <el-radio-button label="value">
                {{ currentCurrencySymbol }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :sm="12" :md="12" :lg="6" :xl="5">
          <!-- Discount Rate/Value -->
          <el-form-item
            v-if="form.type === 'percentage'"
            prop="rate"
            width="20px"
            :label="$t('pages.discounts.edit.form.properties.rate.label')"
          >
            <th-number-input
              id="rate"
              v-model="form.rate"
              class="w-full"
              clearable-is-left
              percent
              :locale="$i18n.locale"
              :upper-limit="1.0"
            />
          </el-form-item>

          <el-form-item
            v-else
            prop="value"
            width="20px"
            :label="$t('pages.discounts.edit.form.properties.value.label')"
          >
            <th-currency-input
              id="value"
              v-model="form.value"
              class="w-full"
              :currency="form.currency"
              clearable-is-left
            />
          </el-form-item>
        </el-col>

        <el-col :sm="12" :md="12" :lg="6" class="flex items-center">
          <!-- Display on receipt -->
          <el-form-item class="mb-0" prop="group_on_receipt">
            <el-checkbox id="group_on_receipt" v-model="form.group_on_receipt">
              {{
                $t(
                  'pages.discounts.edit.form.properties.group_on_receipt.label'
                )
              }}
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :sm="12" :md="12" :lg="7">
          <!-- Location -->
          <el-form-item
            prop="locations"
            :label="$t('common.forms.labels.locations')"
          >
            <available-in
              id="locations"
              :model-value="{
                locations: form.locations,
                branch_groups: form.branch_groups
              }"
              :resources="resources"
              :show-items-limit="2"
              data-testid="locations"
              @update:modelValue="handleAvailableInInput"
            />
          </el-form-item>
        </el-col>

        <el-col v-if="showCurrencySelector" :sm="12" :md="12" :lg="4">
          <!-- Currency -->
          <el-form-item
            :label="$t('common.forms.labels.currency')"
            prop="currency"
          >
            <th-currency-select
              id="currency"
              v-model="form.currency"
              data-testid="currency"
              :allowed-currencies="currencies"
            />
          </el-form-item>
        </el-col>

        <el-col :sm="12" :md="12" :lg="6">
          <!-- Behavior -->
          <el-form-item
            prop="behaviors.cart"
            :label="
              $t('pages.discounts.edit.form.properties.behaviors.cart.label')
            "
          >
            <el-radio-group
              id="behaviors.cart"
              v-model="form.behaviors.cart"
              size="large"
            >
              <el-radio-button label="per_item">
                {{
                  $t(
                    'pages.discounts.edit.form.properties.cart_behaviors.values.per_item'
                  )
                }}
              </el-radio-button>
              <el-radio-button label="per_cart">
                {{
                  $t(
                    'pages.discounts.edit.form.properties.cart_behaviors.values.per_cart'
                  )
                }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :sm="12" :md="12" :lg="5" class="flex items-center">
          <!-- Automatic Application -->
          <el-form-item prop="behaviors.automatic" class="mb-0">
            <el-checkbox
              id="behaviors.automatic"
              v-model="form.behaviors.automatic"
            >
              {{
                $t(
                  'pages.discounts.edit.form.properties.behaviors.automatic.label'
                )
              }}
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import AvailableIn from '@/components/available-in'
import { currencySymbol } from '@/constants'

export default {
  components: {
    AvailableIn
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    resources: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    ...mapGetters({
      currencies: 'Config/getAvailableCurrencies'
    }),
    form: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    },
    rules() {
      return {
        name: [
          {
            required: true,
            message: this.$t('pages.discounts.edit.form.rules.name.required'),
            trigger: 'blur'
          }
        ],
        rate: [
          {
            required: this.form.type === 'percentage',
            message: this.$t('pages.discounts.edit.form.rules.rate.required'),
            trigger: 'blur'
          }
        ],
        value: [
          {
            required: this.form.type === 'value',
            message: this.$t('pages.discounts.edit.form.rules.value.required'),
            trigger: 'blur'
          }
        ],
        'behaviors.cart': [
          {
            required: true,
            message: this.$t(
              'pages.discounts.edit.form.properties.behaviors.cart.required'
            ),
            trigger: 'blur'
          }
        ],
        locations: [
          {
            validator: this.validateLocations,
            trigger: 'blur'
          }
        ]
      }
    },
    currentCurrencySymbol() {
      return currencySymbol[this.form.currency]
    },
    showCurrencySelector() {
      return this.form.type === 'value' && this.currencies.length > 1
    }
  },

  methods: {
    validateLocations: (_, value, callback) => {
      if (value === null || Array.isArray(value)) return callback()
      callback(new Error(this.$t('common.forms.rules.field_warnings.required')))
    },

    handleAvailableInInput(value) {
      this.form.locations = value.locations
      this.form.branch_groups = value.branch_groups
    },

    resetFields() {
      this.$refs.form.resetFields()
    },

    validate() {
      return new Promise((resolve, _) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
